import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Context } from "../../context/store";
import Logo from "../../assets/img/logo.png";
import 'intasend-inlinejs-sdk';


const PayModal = (props) => {
    const [state, dispatch] = useContext(Context);
    const [amount, setAmount] = useState(100);
    const [currency, setCurrency] = useState("KES");

    useEffect(() => {
        new window.IntaSend({
            publicAPIKey: "ISPubKey_live_1fddff70-94d1-40ea-a12a-2c0339edcfd6",
            live: true //or true for live environment
          })
          .on("COMPLETE", (response) => { console.log("COMPLETE:", response) })
          .on("FAILED", (response) => { console.log("FAILED", response) })
          .on("IN-PROGRESS", () => { console.log("INPROGRESS ...") });
    }, []);

    const updatePayload = (ev) => {
        let field = ev.target.name;
        let value = ev.target.value;

        if (field === "amount") {
            setAmount(parseInt(value));
        } else {
            setCurrency(value);
        }
    }

    return (
        <>
            <Modal
            {...props}
            top
            size = "md"
            show={state?.showdonatemodal === "true"}
            onHide={() => dispatch({type:"SET", key:"showdonatemodal", payload:"false"})}
            dialogClassName="buy-ticket-modal"
            aria-labelledby="contained-modal-title-vcenter">
                    <Modal.Header closeButton className="bg-primary text-white justify-center place-items-center pb-0 pt-0">
                        <Modal.Title className="w-full font-small pb-0 pt-0">
                            <div className="flex"><img src={Logo} width="100px" className="inline-block mr-3" alt="pastoral_foundation_payments"/><span className="uppercase inline-block">Donation Form <span className="block text-sm text-gray-600 capitalize">Pastoralist Foundation</span></span></div>
                        </Modal.Title>
                    </Modal.Header>
                    {
                    <Modal.Body className="mt-3">
                        <input className="form-control" name="amount" placeholder="100" onChange={(ev) => updatePayload(ev)}/>
                        <select name="currency" className="form-control my-2" onChange={(ev) => updatePayload(ev)}>
                            <option value="KES">KES</option>
                            <option value="USD">USD</option>
                        </select>
                        <button className="intaSendPayButton bg-primary rounded mt-2 btn capitalize text-white" data-amount={amount} data-currency={currency}>Continue</button>

                        <button className="btn btn-danger float-end capitalize text-sm" onClick={() => dispatch({type: "SET", key: "showdonatemodal", payload: "false"})}>cancel</button>
                    </Modal.Body>
                 }
            </Modal>
        </>
    )
}

export default React.memo(PayModal);