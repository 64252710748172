import React, {useEffect, useCallback, useContext, useState} from "react";
import {render} from "react-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import {
    BrowserRouter,
    Route,
    Routes,
    useNavigate,
} from 'react-router-dom'

import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import Store from './context/store';
import {Context} from './context/store';

import Index  from './components/index';
import {getFromLocalStorage, setLocalStorage} from "./components/utils/local-storage";
import Header from "./components/header";
import Footer from "./components/footer";
import About from "./components/pages/about";
import PartnerWithUs from "./components/pages/partner-with-us";
import WhatWeDo from "./components/pages/what-we-do";
import Projects from "./components/pages/projects";
import ContactUs from "./components/pages/contact-us";
import EmergingIssues from "./components/pages/emerging-issues";
import Donation from "./components/pages/donation";


const Logout = () => {
    let navigate = useNavigate();

    const [state, dispatch] = useContext(Context);
    const out = useCallback(() => {
        localStorage.clear();
        dispatch({type: "DEL", key: "user"});
        navigate("/");
    }, [navigate]);

    useEffect(() => {
        out();
    }, [out]);
    return null;
}

const App = () => {

    const [state, ] = useContext(Context);
    const isAuthenticated = getFromLocalStorage("user");
    return (
                <>
                
                <div className="">

                <div className="h-screen">
                    <div className="flex min-h-full flex-col">
                        <Header />
                        <div class="flex-1">
                          <Routes>
                            <Route exact path="/" element={<Index/>}/>
                            <Route exact path="/about-us" element={<About/>}/>
                            <Route exact path="/what-we-do" element={<WhatWeDo/>}/>
                            <Route exact path="/partnering" element={<PartnerWithUs />}/>
                            <Route exact path="/projects" element={<Projects />}/>
                            <Route exact path="/contact-us" element={<ContactUs />}/>
                            <Route exact path="/emerging-issues" element={<EmergingIssues />}/>                            
                            <Route exact path="/donate" element={<Donation/>}/>                            
                            <Route path="*" element={<Index/>}/>
                          </Routes>
                        </div>
                        <Footer />
                    </div>
                    
                </div>
                
              </div>
              
              </>
    )
}

const container = document.getElementById("app");

render((
    <Store>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Store>
), container);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
