import React, {useEffect, useState} from "react";
import PageTitle from "../sections/page-title";
import 'intasend-inlinejs-sdk';

const Donation = (props) => {
    const [amount, setAmount] = useState(100);
    const [currency, setCurrency] = useState("KES");

    const updatePayload = (ev) => {
        let field = ev.target.name;
        let value = ev.target.value;

        if (field === "amount") {
            setAmount(parseInt(value));
        } else {
            setCurrency(value);
        }
    }

    useEffect(() => {
        new window.IntaSend({
            publicAPIKey: "ISPubKey_live_1fddff70-94d1-40ea-a12a-2c0339edcfd6",
            live: true //or true for live environment
          })
          .on("COMPLETE", (response) => { console.log("COMPLETE:", response) })
          .on("FAILED", (response) => { console.log("FAILED", response) })
          .on("IN-PROGRESS", () => { console.log("INPROGRESS ...") });
    }, []);
    return (
        <>
            {/* <PageTitle title="Projects" /> */}

            <section className="my-10">
                <div className="container">
                    <div className="mx-auto card" style={{maxWidth:"400px"}}>
                        <div className="bg-primary card-header text-white text-center font-bold">Donation Form</div>
                        
                        <div className="card-body">
                        
                        <label>Select Currency</label>
                        <select name="currency" className="form-control my-2" onChange={(ev) => updatePayload(ev)}>
                            <option value="KES">KES</option>
                            <option value="USD">USD</option>
                            <option value="JPY">JPY</option>
                            <option value="AUD">AUD</option>
                            <option value="GBP">GBP</option>
                            <option value="CNY">CNY</option>
                            <option value="CAD">CAD</option>
                        </select>

                        <label className="mt-2">Enter Amount</label>
                        <input className="form-control" name="amount" placeholder="100" onChange={(ev) => updatePayload(ev)}/>
                        
                        <button className="intaSendPayButton mt-4 hover:opacity-80 bg-primary rounded mt-2 btn capitalize text-white w-full" data-amount={amount} data-currency={currency}>Continue</button>
                        </div>
                    
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(Donation);