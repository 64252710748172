import React from "react";
import Rhamu from "../../../assets/img/posts/rhamu-school.png"

const AboutSection = (props) => {

    return (
        <>
            <p>
                Pastoralist Foundation for Poverty Reduction is a non-profit making, charitable, development, and humanitarian organization registered with the government of Kenya and the NGO Co-ordination board since 2006.
            </p>
            <p>
                The organization was formed by a group of local communities, professionals, and elites of all gender who felt it was important to identify the most feasible ways to transform and redefine the lives of hundreds of thousands of vulnerable people in the pastoralist and nomadic communities and create better living conditions by putting in place a conducive environment for peacebuilding and supporting community growth.
            </p>
            <p>
                The organization was formed so as to deliver faster, deeper, and broader approaches to community-based development initiatives committed to reducing poverty. The organization shall assess the challenges through defined participatory appraisals and set out an overall approach and strategies leading to the achievement of sustainable community development targets in a clear, transparent, focused, and realistic way in consultation with its donors, partners, communities, and other stakeholders in the county.
            </p>
            <h4 className="small-title">
                Our projects empower people to make positive, lasting changes in their lives
            </h4>
            <p>
                Our work increases incomes improve livelihoods and create opportunities for those who have traditionally been excluded. Our programs in Madera County and its environs inform how we–in close collaboration with our funders, partners, staff, and beneficiaries–achieve desired development outcomes and positively impact targeted communities. We design and implement major agriculture, economic development, and resilience programs in ASAL counties.
            </p>

            <h2 className="title">
                Schools in Rhamu
            </h2>
            <img src={Rhamu} />

            <p>
                Significant investments in children and women in recent years have led to developmental successes in Kenya. Notably in primary education. The close proximity of Mandera County to Somalia has always put it at risk of violent extremism, terrorism, and insecurity in general. School infrastructure and general development are among the lowest in the country. Basic improvement on existing structures can be of significant benefit to learners and the community at large
            </p>

            <h2 className="title">Al- Furqan integrated institution</h2>
            <p>
                Al- Furqan integrated institution was started by a group of professionals from Rhamu division of Mandera North sub-county. The group of professionals saw the need to consolidate and harmonize resources and personnel for the betterment of the community. AlFurqan was run informally until it was registered in December 2004.
            </p>
            <p>
                Currently, Our organization supports a primary school that offers basic education to 867 students. The school is a joint Public-private partnership, With the Kenya Government paying Teachers and the School board of governors managing day to day running of the school. In mutual partnership with Pastoralist Foundation.
            </p>
            <p>
                Since then the group has partnered with TSC and the Ministry of Education to pay for Teachers in 8.4.4 Systems of Education and Our Organization Ansaar International East Africa Initiative to sponsor the Madrasa and religious education section.  Pastoralist foundation runs several activities that have now produced outstanding benefits to the community.
            </p>
            <p>
                The day-to-day running of the School is sponsored by our organization Pastoralist Foundation for Poverty Reduction, however, there are a few projects undertaken by community development fund (CDF) from the government and other well-wishers. The community has participated in the provision of land, financial contribution, and offering voluntary services to ensure the smooth running of the school. They have also been part of every key decision-making and representation on the board. The school is being run by a Board of Governors who sat and identified the need to Fence the School and build a boarding facility for both Girls and Boys. and the school board also wrote to Pastoralist Foundation to help source for Funding the same.
            </p>
            <p>
                Pastoralist Foundation for Poverty Reduction is a new applicant of the fund Social cooperate responsibility, In addition, the organization is founded by local professionals of all gender, It was legally registered as a local NGO, now with a focus on East Africa and Horn of African Countries, Somalia and even Ethiopia.
            </p>
            <p>
                Since the inception of the project, the available resources have been used for the planned activities with the approval of the board. In addition, Pastoralist Foundation for Poverty Reduction has strived to mainstream monitoring and evaluation in project management to ensure that the project to be implemented is cost-effective.
            </p>
        </>
    )
}

export default React.memo(AboutSection);