import React from 'react';
import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';
// import Slider1 from "../../assets/img/slider/1.png";
import Slider2 from "../../assets/img/slider/2.png";
import Slider6 from "../../assets/img/slider/6.png";
import Slider4 from "../../assets/img/slider/4.png";
import Slider5 from "../../assets/img/slider/5.png";
import { Link } from 'react-router-dom';


const Carousel = (props) => {

    return (
        <>
            <MDBCarousel showControls>
                <MDBCarouselItem itemId={1}>
                    <img src={Slider6} className='d-block w-100 main-image' alt='...' />

                    <MDBCarouselCaption>
                        
                        <div className='carousel-text px-3 py-3'>
                            <h4 className='carousel-title text-2xl md:text-5xl py-1 md:py-3'>Give a little. <span className='primary-text-color'>Change a lot</span></h4>
                            <p className='mb-1'>But I must first make you understand the importance of this program to the society</p>
                            <Link className='mt-3 md:mt-2 w-full md:w-auto btn rounded-md shadow-sm btn-primar bg-purple bg-primary text-white md:mt-3 px-4' to={"/donate"}>Donate Now</Link>
                        </div>
                    </MDBCarouselCaption>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={2}>
                    <img src={Slider2} className='d-block w-100 main-image' alt='...' />
                    <MDBCarouselCaption className="md:w-1/2">
                        <div className='carousel-text px-3 pt-3'>
                            <h4 className='carousel-title text-2xl md:text-5xl py-1 md:py-3'>Pastoralist Foundation. <span className='primary-text-color'>For Poverty Reduction</span></h4>
                            <p className='mb-1 md:block hidden'>An NGO formed and Registered in Kenya to help identify the most feasible ways to transform and redefine the lives of hundreds of thousands of vulnerable people in the pastoralist and nomadic communities and create a better living conditions by putting in place a conducive environment for peace building and supporting community growth.</p>
                            
                            <Link className='mt-3 md:mt-2 w-full md:w-auto btn rounded-md shadow-sm btn-primar bg-purple bg-primary text-white md:mt-3 px-4' to={"/donate"}>Donate Now</Link>
                        </div>
                    </MDBCarouselCaption>
                </MDBCarouselItem>
            </MDBCarousel>
        </>
    )
}

export default React.memo(Carousel);