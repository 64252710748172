import React from "react";
import PageTitle from "../sections/page-title";
import Accordion from 'react-bootstrap/Accordion';
import {emergingIssuesList} from "../sections/emerging-issues-articles";
import parse from 'html-react-parser';


const EmergingIssues = (props) => {
    return (
        <>
            <PageTitle title="Emerging Issues" />
            <section className="my-6">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Accordion defaultActiveKey="">
                                {emergingIssuesList.map((item, ind) => 
                                    <Accordion.Item eventKey={ind}>
                                        <Accordion.Header>{item.title}</Accordion.Header>
                                        <Accordion.Body>
                                            <img src={require(`../../assets/img/posts/${item.coverPhoto}`)} alt="camel" width={"100%"}/>
                                            <h2 className="mt-2 title primary-text-color">{item.headline}</h2>
                                            <p className="mt-2">
                                                {parse(item.intro)}
                                            </p>

                                            {item?.pics?.map((pic, ind) =>
                                                <img src={require(`../../assets/img/posts/${pic}`)} className="inline-block mr-2 mt-2" width={"200px"}/>
                                            )}
                                            <p className="">
                                                {parse(item.body)}
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                            )}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(EmergingIssues);