import React from "react";
import Logo from "../assets/logo.png";
import { FaHome, FaPhoneAlt, FaFacebook, FaTwitter, FaInstagram, FaPinterest } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { Gallery } from "react-grid-gallery";
import { IoMdSend } from "react-icons/io";


const images = [
    {
       src: require(`../assets/img/gallery/1.png`),
       width: 100,
       height: 100,
       isSelected: false,
       caption: "After Rain (Jeshu John - designerspics.com)",
    },
    {
        src:require(`../assets/img/gallery/2.png`),
        width: 100,
       height: 100,
       alt: "Boats (Jeshu John - designerspics.com)",
    },
    {
        src:require(`../assets/img/gallery/5.png`),
        width: 100,
       height: 100,
    },
    {
        src:require(`../assets/img/gallery/6.png`),
        width: 100,
       height: 100,
    },
 ];
const Footer = (props) => {

    return (
        <footer className="">
            <section id="" className="bg-dark-2 py-6">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="flex std-block">
                                <img src={Logo} width={90} className="inline-block mr-3"/> 
                                <div className="inline-block">
                                    <div className="text-2xl primary-text-color font-bold uppercase leading-2">
                                        <span className="text-2xl">P</span>astoralist <span className="text-3xl primary-text-color">F</span>oundation
                                    </div>
                                    <div className="text-sm">For Poverty Reduction</div>
                                </div>
                            </div>    
                            <div className="block py-2 std-block">
                                Contrary to popular belief, Our foundation works with the pastoralist communities on the ground to identify the actual problems and solve the issues with informed conscience.
                            </div>
                            <div className="block py-2 std-block">
                                <div className="mb-2">
                                    <FaHome size={20} className="inline-block primary-text-color mr-2"/> Chai House,8th floor –Koinange Street
                                </div>
                                <div className="mb-2">
                                    <FaPhoneAlt size={20} className="inline-block primary-text-color mr-2"/> 254722833402
                                </div>
                                <div className="mb-2">
                                    <MdEmail size={20} className="inline-block primary-text-color mr-2"/> info@pastoralistfoundation.org
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="single-footer-widget">
                                <h4 className="capitalize">Quick links</h4>
                                <ul className="footer-list">
                                    <li><Link to="/about-us">About Us</Link></li>
                                    <li><Link to="/partners">Partners</Link></li>
                                    <li><Link to="/emerging-issues">Emerging issues</Link></li>
                                    <li><Link to="/projects">Projects</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="single-footer-widget">
                                <h4 className="capitalize">Gallery</h4>
                                <Gallery images={images} />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="single-footer-widget">
                                <h4>Subscribe to Us</h4>

                                <div class="footer-newsletter">
                                    <p>Subscribe to our newsletter!</p>
                                    <div class="newsletter-form mc_embed_signup">
                                        <form action="" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate="">
                                            <div id="mc_embed_signup_scroll" className="mc-form">
                                                <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Email address...." required="" />
                                                <div class="mc-news" aria-hidden="true"><input type="text" name="" tabindex="-1" value="" /></div>
                                                <button id="mc-embedded-subscribe" type="submit" name="subscribe"><IoMdSend className="primary-text-color"/></button>
                                            </div>
                                        </form>
                                    </div>
                                    <p>You could follow us on our social media links below</p>
                                </div>
                                <div class="footer-social-link mt-3">
                                    <a href="https://www.facebook.com/pastoralist-foundation/" 
                                        target="_blank"
                                        rel="noreferrer"
                                        className="mr-3 inline-block">
                                            <FaFacebook size={30} />
                                    </a>
                                    <a href="https://twitter.com/FPastoralist"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="mr-3 inline-block">
                                            <FaTwitter  size={30}/>
                                    </a>
                                    <a href="https://www.instagram.com/pastoralist-foundation/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="mr-3 inline-block">
                                            <FaInstagram  size={30}/>
                                    </a>
                                    <a href="https://www.pinterest.com/pastoralist-foundation/"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="mr- inline-block">
                                            <FaPinterest  size={30}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-black text-gray-400">
                <div className="container py-3">
                    <div className="row">
                        <div className="col-12 text-center">
                            Managed by &copy;<a href="http://www.mart-systems.com">MART Systems</a>
                        </div>
                    </div>
                </div>
            </section>

        </footer>
    )
}

export default React.memo(Footer);