import React from "react";
import Carousel from "./sections/carousel";
import Intro from "./sections/intro-block";
import FeaturedCause from "./sections/featured";

const Index = (props) => {

    return(
        <>
            <Carousel />
            <Intro />
            <FeaturedCause />
        </>
    )
}
export default React.memo(Index);