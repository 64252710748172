import React from "react";

const Intro = (props) => {

    return (
        <section id="" className="pt-90 pb-95 py-7">
            <div className="container text-center md:my-7">
                <div class="help-text py-7">
                    <h1 class="help-title">What we do</h1>
                    <p>The main purpose of the organization is to improve livelihood systems and reduce poverty through community owned and managed sustainable natural resource based among communities in arid and Semi-Arid lands </p>
                </div>
            </div>
        </section>
    )
}

export default React.memo(Intro);
