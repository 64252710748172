import React from "react";
import Organogram from "../../../assets/img/posts/organogram.png";
const Team = (props) => {
    return (
        <>
            <h2 className="title text-center mb-3 !uppercase">
                The Organization’s Governance structure
            </h2>
            <img src={Organogram} className="mb-2 mx-auto"/>

            <h2 className="title">The Organization’s Critical Departments</h2>
            <h2 className="title">Operations</h2>
            <p>This is the nerve center of everything going o at the organization. it is the like between the programs, people, and our donors</p>
            <h2 className="title">
                Programs
            </h2>
            <p>The department handles all issues to do with:</p>
            <ul className="font-bold">
                <li>GOVERNANCE</li>
                <li>PEACE-BUILDING</li>
                <li>CONFLICT RESOLUTION</li>
                <li>CIVIC EDUCATION</li>
            </ul>
            <ul>
                <li>Youth Empowerment</li>
                <li>Women empowerment</li>
                <li>Support for Nomads</li>
            </ul>
            <h2 className="title">Finance</h2>
            <p>Let the smooth running of the organization possible</p>
        </>
    )
}
export default React.memo(Team);