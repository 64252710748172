import React from "react";
import PageTitle from "../sections/page-title";

const Projects = (props) => {
    return (
        <>
            <PageTitle title="Projects" />

            <section className="my-10">
                <div className="container">
                    <div className="p-4 border border-gray-300 rounded-2xl">Currently, there are no records for Projects</div>
                </div>
            </section>
        </>
    )
}

export default React.memo(Projects);