import React from "react";
import Heart from "../../assets/img/heart.png";
import { Link } from "react-router-dom";
import Countdown from 'react-countdown';



const FeaturedCause = (props) => {
    
    const timeLeft = (endDate) => {
        let dif = (new Date(endDate)) - Date.now();
        let days = Math.floor(dif  / (1000 * 60 * 60 * 24));
        let hrs = Math.floor((dif - (days * 1000 * 60 * 60 * 24)) /(1000 * 60 * 60));
        if (days > 0) {
            days = `${days} days`
        } else {
            days = ""
        }

        if (hrs > 0) {
            hrs = `${hrs} hrs`;
        } else {
            hrs = ""
        }
        return `${days} ${hrs}`
    }
    return (
        <section className="cause-area pb-5 md:pb-100">
            <div className="container">
                <div className="section-title text-center">
                    <h1>Featured <span className="primary-text-color">Causes</span></h1>
                    <img src={Heart} alt="" className="mx-auto"/>
                </div>

                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="ht-single-cause mb-3">
                            <div className="ht-cause-img">
                                <a href="cause-details.html">
                                    <img src={require("../../assets/img/cause/1.png")} className="w-full" alt=""/>
                                </a>
                            </div>
                            <div className="ht-cause-text">
                                <div className="progress-wrap">
                                    <div className="progress">
                                        <div className="progress-bar wow fadeInLeft" data-progress="60%" style={{width: '60%', visibility: "visible", animationDuration: "1.5s", animationDelay: "0.3s", animationName: "fadeInLeft"}} data-wow-duration="1.5s" data-wow-delay=".3s">
                                            <div className="text-top z-index-90">60%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ht-cause-info">
                                    <span className="ht-s-info">Raised: Over 3,000,000</span>
                                    <span className="ht-s-info">Target: 5,000,000</span>
                                </div>
                                <h3 className="text-center cause-title"><a href="cause-details.html">Democracy And Governance</a></h3>
                                <div className="cause-desc">
                                    <p>
                                        Civic education on devolution, leadership accountability, and transparency in devolved government.

                                        Support and advocate human rights.

                                        Gender mainstreaming.

                                        Support Equity, justice
                                    </p>
                                </div> 
                                <Link to={"/what-we-do#democracy-and-governance"} className="cause-read-more">Read more</Link>
                                <div className="cause-btn-time">
                                    <Link className='mt-3 md:mt-2 w-full md:w-auto btn rounded-md shadow-sm btn-primar bg-purple bg-primary text-white md:mt-3 px-2' to={"/donate"}>Donate Now</Link>
                                    <span className="cause-time font-bold">{timeLeft("2024,05,15")}, Left</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="ht-single-cause mb-3">
                            <div className="ht-cause-img">
                                <a href="cause-details.html">
                                    <img src={require("../../assets/img/cause/2.jpg")} className="w-full" alt=""/>
                                </a>
                            </div>
                            <div className="ht-cause-text">
                                <div className="progress-wrap">
                                    <div className="progress">
                                        <div className="progress-bar wow fadeInLeft" data-progress="80%" style={{width: '80%', visibility: "visible", animationDuration: "1.5s", animationDelay: "0.3s", animationName: "fadeInLeft"}} data-wow-duration="1.5s" data-wow-delay=".3s">
                                            <span className="text-top">80%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="ht-cause-info">
                                <span className="ht-s-info">Raised: Over 4,000,000</span>
                                    <span className="ht-s-info">Target: 5,000,000</span>
                                </div>
                                <h3 className="text-center cause-title"><a href="cause-details.html">Peace Building</a></h3>
                                <div className="cause-desc">
                                    <p>Peace Building in Mandera Triangle (Communities living in Mandera and the neighboring Counties of Wajir and Marsabit and a Cross border of Somalia and Ethiopia) in the future with the availability of funding we intend to extend our humanitarian service to all of East Africa and Horn of Africa countries.

                                        Revive traditional conflict resolution and management systems.

                                        Create monitoring of conflict and early warning systems among warring pastoralists during electioneering and during the long drought.

                                        Create a peace-village at a central place within Mandera County which will act as an education center for civil society groups engaged in peace.

                                        Supplement government efforts on strengthening community policing mechanisms on organized criminals, smuggling of hard drugs, small arms, and human trafficking along porous international borders in the larger Mandera Triangle
                                    </p>
                                </div>
                                <Link to={"/what-we-do#democracy-and-governance"} className="cause-read-more">Read more</Link>
                                <div className="cause-btn-time">
                                <Link className='mt-3 md:mt-2 w-full md:w-auto btn rounded-md shadow-sm btn-primar bg-purple bg-primary text-white md:mt-3 px-2' to={"/donate"}>Donate Now</Link>
                                    <span className="cause-time font-bold">{timeLeft("2024,06,29")}, Left</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="ht-single-cause mb-3">
                            <div className="ht-cause-img">
                                <a href="cause-details.html">
                                    <img src={require("../../assets/img/cause/3.png")} className="w-full" alt=""/>
                                </a>
                            </div>
                            <div className="ht-cause-text">
                                <div className="progress-wrap">
                                    <div className="progress">
                                        <div className="progress-bar wow fadeInLeft" data-progress="60%" style={{width: '60%', visibility: "visible", animationDuration: "1.5s", animationDelay: "0.3s", animationName: "fadeInLeft"}} data-wow-duration="1.5s" data-wow-delay=".3s">
                                            <span className="text-top">60%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="ht-cause-info">
                                <span className="ht-s-info">Raised: Over 3,000,000</span>
                                    <span className="ht-s-info">Target: 5,000,000</span>
                                </div>
                                <h3 className="text-center cause-title"><a href="cause-details.html">Youth and Women Empowerment</a></h3>
                                <div className="cause-desc">
                                    <p>
                                        Education for Girl-child and orphans and vulnerable children by creating boarding orphan centers.

                                        De-radicalization and reintegration of youths into society.

                                        Support Post-Secondary Education, Supply sanitary pads to needy girls in schools

                                        Support Sports as entertainment and occupation for youths.

                                        Civic Education -Community empowerment through resource mobilization and livelihood support program.
                                    </p>
                                </div>
                                <Link to={"/what-we-do#democracy-and-governance"} className="cause-read-more">Read more</Link>
                                <div className="cause-btn-time">
                                    <Link className='mt-3 md:mt-2 w-full md:w-auto btn rounded-md shadow-sm btn-primar bg-purple bg-primary text-white md:mt-3 px-2' to={"/donate"}>Donate Now</Link>
                                    <span className="cause-time font-bold">{timeLeft("2024,08,10")}, Left</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default React.memo(FeaturedCause);