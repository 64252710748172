import React, { useState } from "react";
import PageTitle from "../sections/page-title";
import Donors from "../../assets/img/posts/donors.jpeg";
import Vacancies from "../../assets/img/posts/vacancies.jpeg";
import Heart from "../../assets/img/heart.png";
import { Link } from "react-router-dom";

const PartnerWithUs = (props) => {
    const [contactSuccess, setContactSuccess] = useState(null);
    const handleSubmit = () => {

        setContactSuccess(true);
        return;
    }

    const fieldChanged = () => {
        console.log("Some field validation");
    }
    return (
        <>
            <PageTitle title="Partner With us" />

            <section>
                <div className="container">
                    <h2 className="title">Our community</h2>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h2 className="title primary-text-color">Donors</h2>
                            <img src={Donors} alt="donors" width={"100%"} className="mb-2"/>
                            <div className="mx-auto"><Link className="btn rounded-md shadow-sm btn-primar bg-primary text-white bg-primary bg-purple mt-3 px-4" to={"/donate"}>Donate Now</Link><a href={"/partnering#contactNow"} className="btn rounded-md shadow-sm btn-primar bg-primary text-white bg-primary mt-3 px-4 mx-4">Inquire Now</a></div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h2 className="title primary-text-color">Vacancies</h2>
                            <img src={Vacancies} alt="vacancies" width={"100%"} className="mb-2"/>
                            <div className="mx-auto"><a href={"/partnering#contactNow"} className="mx-auto btn rounded-md bg-primary bg-purple shadow-sm btn-primar bg-primary text-white mt-3 px-4">Apply Job</a></div>
                        </div>

                        
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center" >
                            <div className="section-title text-center mt-5">
                                <h1>Come Work <span className="primary-text-color">With Us</span></h1>
                                <img src={Heart} alt="" className="mx-auto"/>
                            </div>
                            <p>
                                If you’re a potential public sector donor, a government or foundation representative who would like to design and implement projects with us
                            </p>
                            <p>Please contact <strong>info@pastoralistfoundation.org </strong>or Drop us a message on the form below</p>

                            <div className="my-3 mx-auto card" style={{maxWidth:"400px"}}>
                                <div className="card-header bg-primary text-white font-bold capitalize" id="contactNow">Send a Message</div>
                                <form className="card-body" onSubmit={() => handleSubmit()}>
                                    <input className="form-control mb-2" placeholder="email Address"name="email" type="email" onChange={(ev) => fieldChanged(ev)}/>
                                    <input className="form-control mb-2" placeholder="enter Subject"/>
                                    <textarea className="form-control" placeholder="Enter Message"></textarea>
                                    <button className="w-full btn bg-primary text-white mt-2">Send</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(PartnerWithUs);