import React from "react";
import PageTitle from "../sections/page-title";

const WhatWeDo = (props) => {
    return (
        <>
            <PageTitle title = "Our Programs" />
            <section className="py-10">
                <div className="container">
                    <h2 className="title">Governance</h2>
                    <p>We have programs around Civic Education, ensuring inclusive participation and constructive engagement between the duty bearers and rights holders, and ensuring effective and efficient service delivery in areas of Economic, Social, Ecological/Environmental, Cultural, and Civic/Political Rights</p>
                    <h2 className="title">Women Empowerment</h2>
                    <p>Women are the cornerstones o our communities yet they have not been brought forth enough on the decision table. We have programs to empower women economically.</p>
                    <h2 className="title">Youth Rehabilitation</h2>
                    <p>
                        One of our aims is to give young people not only options and ambitions, but the confidence to work towards achieving them, We arrange activities which the young people lead themselves, like debates and tournaments, and we make sure girls are very much as central to these as the boys. We want all of them to know their rights, to be able to question things and to have self-esteem. Among the most popular sessions are those on youth participation in local politics – followed closely by training on how to write clear CVs and how to use basic word processing and accounting software.
                    </p>
                    <h2 className="title">Environment</h2>
                    <p>Our land mass faces severe drought every year ad efforts are being made to conserve and properly utilize it for better production of food crops and domestic animals. Also we encourage conservation along  River Daua , both flora, and fauna</p>
                </div>
            </section>
        </>
    )
}

export default React.memo(WhatWeDo);