import React, {useContext, useEffect, useState} from "react";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt, FaBars } from "react-icons/fa";
import Logo from "../assets/logo.png";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation, Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import PayModal from "./utils/pay-modal";
import { Context } from "../context/store";

const TopHeader = (props) => {
    const location = useLocation();
    const [_, dispatch] = useContext(Context);


    return (
        <>
            <section className={`bg-black text-gray-300 ${location.pathname === "/" ? "md:block hidden" :'block'}`}>
                <div className="container">
                    <div className="row md:flex">
                        <div className="col-md-6 col-sm-12 py-1">
                            <span className="">
                                <MdEmail size={20} className="inline-block"/> <span className="mr-4 text-sm md:text-base">info@pastoralistfoundation.org</span> <span className="block md:inline-block"><FaPhoneAlt size={20} className="inline-block md:ml-4"/> 254722833402</span>
                            </span>
                        </div>
                        <div className="col-md-6 col-sm-">
                            <div className="my-2 md:!my-0 text-right">
                                <Link
                                to={"/donate"}
                                className="w-full md:w-auto btn bg-primary bg-purple text-white rounded-md md:rounded-none"
                                >
                                    Donate Now
                                </Link>
                                <button className="intaSendPayButton bg-primary rounded mt-2 btn capitalize text-white opacity-0" data-amount={"100"} data-currency={"KES"}>Continue</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const Brand = (props) => {
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    // new window.IntaSend({
    //     // Replace with your Publishable Key
    //     publicAPIKey: "ISPubKey_test_91ffc81a-8ac4-419e-8008-7091caa8d73f",
    //     live: false //set to true when going live
    //     })
    //     .on("COMPLETE", (results) => console.log("Do something on success", results))
    //     .on("FAILED", (results) => console.log("Do something on failure", results))
    //     .on("IN-PROGRESS", (results) => console.log("Payment in progress status", results));
    return (
        <>
        
            <section>
                
                        <Navbar bg="light" data-bs-theme="light">
                            <Container>
                                    <Navbar.Brand href="/" className="">
                                        <img src={Logo} width={70} className="inline-block mr-3" alt=""/> 
                                        <div className="inline-block">
                                            <div className="font-medium primary-text-color uppercase logo-text"><span className="text-3xl">P</span>astoralist <span className="text-3xl">F</span>oundation</div>
                                            <div className="text-sm ">For Poverty Reduction</div>
                                        </div>
                                    </Navbar.Brand>
                                    <div className="md:hidden">
                                        <FaBars variant="toggle-menu" size={25} onClick={handleShow} className='inline-block'/>
                                    </div>
                                    <Nav className="font-medium uppercase hidden md:flex">
                                        <Link to="/" className={`nav-link ${location.pathname === "/" || location.pathname === "/home" ? "active primary-text-color": ""}`}>Home</Link>
                                        <Link to="/about-us" className={`nav-link ${location.pathname.includes("about") ? "active primary-text-color": ""}`}>About</Link>
                                        <Link to="/what-we-do" className={`nav-link ${location.pathname.includes("what-we")  ? "active primary-text-color": ""}`}>What we Do</Link>
                                        <Link to="/partnering" className={`nav-link ${location.pathname.includes("partner")  ? "active primary-text-color": ""}`}>Partnering</Link>
                                        <Link to="/emerging-issues" className={`nav-link ${location.pathname.includes("emerging")  ? "active primary-text-color": ""}`}>Emerging Issues</Link>
                                        <Link to="/projects" className={`nav-link ${location.pathname.includes("projects")  ? "active primary-text-color": ""}`}>Projects</Link>
                                        <Link to="/contact-us" className={`nav-link ${location.pathname.includes("contact")  ? "active primary-text-color": ""}`}>Contact</Link>
                                    </Nav>

                            </Container>
                        </Navbar>

                        <Navbar className="md:hidden">
                            {/* Only visible on mobile as 3 key menu items */}
                            <div className="w-full mt-2">
                                        <Nav className="font-medium uppercase md:hidden mobile-main-menu">
                                            <Link to="/" className={`nav-link ${location.pathname === "/" || location.pathname === "/home" ? "active primary-text-color": ""}`}>Home</Link>
                                            <Link to="/partnering" className={`nav-link ${location.pathname.includes("partner")  ? "active primary-text-color": ""}`}>Partnering</Link>
                                            <Link to="/projects" className={`nav-link ${location.pathname.includes("projects")  ? "active primary-text-color": ""}`}>Projects</Link>
                                            <Link to="/contact-us" className={`nav-link ${location.pathname.includes("contact")  ? "active primary-text-color": ""}`}>Contact</Link>
                                        </Nav>
                                    </div>
                        </Navbar>

                        <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton className="bg-black text-white">
                            <Offcanvas.Title>Main Menu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div>
                                <ul className="list-group list-group-light">
                                <li class="list-group-item" onClick={() => handleClose()}><Link to="/" className={`nav-link ${location.pathname === "/" || location.pathname === "/home" ? "active primary-text-color": ""}`}>Home</Link></li>
                                <li class="list-group-item" onClick={() => handleClose()}><Link to="/about-us" className={`nav-link ${location.pathname.includes("about") ? "active primary-text-color": ""}`}>About</Link></li>
                                <li class="list-group-item" onClick={() => handleClose()}><Link to="/what-we-do" className={`nav-link ${location.pathname.includes("what-we")  ? "active primary-text-color": ""}`}>What we Do</Link></li>
                                <li class="list-group-item" onClick={() => handleClose()}><Link to="/partnering" className={`nav-link ${location.pathname.includes("partner")  ? "active primary-text-color": ""}`}>Partnering</Link></li>
                                <li class="list-group-item" onClick={() => handleClose()}><Link to="/emerging-issues" className={`nav-link ${location.pathname.includes("emerging")  ? "active primary-text-color": ""}`}>Emerging Issues</Link></li>
                                <li class="list-group-item" onClick={() => handleClose()}><Link to="/projects" className={`nav-link ${location.pathname.includes("projects")  ? "active primary-text-color": ""}`}>Projects</Link></li>
                                <li class="list-group-item" onClick={() => handleClose()}><Link to="/contact-us" className={`nav-link ${location.pathname.includes("contact")  ? "active primary-text-color": ""}`}>Contact</Link></li>
                                </ul>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    
            </section>
        </>
    )
}
const Header = (props) => {

    return (
        <>
            <TopHeader />
            <Brand />

            <PayModal />
        </>
    )
}

export default React.memo(Header);