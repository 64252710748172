import React from "react";
import Mission from "../../../assets/img/posts/mission.jpg";

const MissionSection = (props) => {
    return (
        <>
            <h1 className="title">
                Introduction
            </h1>
            <p><strong>We’re the beacon of hope to Pastoralists from Mandera. </strong></p>
            <p>We set the following programs for our Community: </p>
            <ul className="square-list">
                <li>Education – Early Childhood Education- Founded and Supported intiation of  Rhamu integrated Primary school. Also supported Girl child education through supply of sanitary pads to girls in primary schools and discouraging harmful traditional practices such as FGM/Cut and early marriages</li>
                <li>Agricultural sector-Support to small scale farmers along Daua River,Educating farmers on effect of climate change and supplying them with proper seed suitable for area.</li>
                <li>Water and sanitation (WASH)</li>
                <li>Conflict and disaster Response mitigation (Plan is  underway to start  a Peace village at a selected site in Mandera County where traditional and modern conflict resolution methods may be explored and appropriately adapted )</li>
                <li>Leadership accountability and transparency</li>
                <li>Environmental  education  and conservation along  River Daua ,both flora and fauna</li>
                <li>Democracy and governance – Civic education to the general populace on devolution and County Government’s role and structure.</li>
                <li>Strengthen Community policing to promote peaceful coexistence among neighboring communities.</li>
            </ul>
            
            <p></p>
            <img src={Mission} className="w-full mb-2"/>
            <h2 className="title">OUR MISSION</h2>
            <p>Promoting community-driven humanitarian and development approaches so as to transform poverty and marginalization of the community by mobilizing their consciousness for self-determination, equity and improved livelihood securities and reducing Family Relief dependency.</p>
            <h2 className="title">VISION</h2>
            <p>To create a value- driven indigenous society of persons who consciously work for a sustainable and improvement of their livelihoods that are committed to positive action in transforming vulnerabilities and build an environment of development, justice and peace where basic needs are met and human rights and dignity are upheld.</p>
            <p></p>
            <h2 className="title">CORE VALUES</h2>
            <ul className="square-list">
                <li>Peace Building in Mandera Triangle(Communities living in Mandera and the neighboring Cross borders of Somalia and Ethiopia )</li>
                <li>Youth empowerment, deradecalization and reintegration into society.</li>
                <li>Education for all-Girl child and orphans and volnarables</li>
                <li>Community empowerment through resource mobilization and livelihood support programme.</li>
                <li>Democracy, equality, freedom and justice.</li>
                <li>Accountability and transparency in Devolved Government.</li>
            </ul>
        </>
    )
}

export default React.memo(MissionSection);