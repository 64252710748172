import React from "react";
const PageTitle = (props) => {
    const {title} = props;
    return (
        <>
            <section className="page-title">
                <div className="container">{title}</div>
            </section>
        </>
    )
}

export default React.memo(PageTitle);