import React from "react";
import PageTitle from "../sections/page-title";

const ContactUs = (props) => {
    return (
        <>
            <PageTitle title="Contact Us" />
            <section className="py-10">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="title primary-text-color">Contact Person</h2>
                            <strong>Name:</strong> Hussein Mahat <br/>
                            <strong>Email:</strong> info@pastoralistfoundation.org<br/>
                            <strong>Phone:</strong> 254722833402<br/>
                            <strong>Work Hours:</strong> Monday - Friday, 8:00 am - 5:00 pm<br/>
                        </div>

                        <div className="col-md-6">
                            <h2 className="title primary-text-color">Location</h2>
                            <strong>Nairobi Head Office</strong><br/>
                            ♦Chai House,8th floor –Koinange Street<br/>
                            P.O.BOX 33361-00600, Nairobi <br/>
                            <strong>Mandera West Office</strong><br/>
                            Takaba Town<br/>
                            Mandera County
                        </div>
                        <div className="col-12 my-5">
                        <p class="boldgrid-google-maps">
                            <iframe style={{maxWidth: "100%;"}} src="https://maps.google.com/maps?q=koinange%20street%2Cnairobi&amp;t=m&amp;z=16&amp;output=embed" width="100%" height="600" frameborder="0"></iframe>
                        </p>
                        
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(ContactUs);