import React, {useState} from "react";
import PageTitle from "../sections/page-title";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AboutSection from "../sections/about/about";
import MissionSection from "../sections/about/mission";
import Team from "../sections/about/team";

const About = (props) => {
    const [key, setKey] = useState('about');


    return (
        <>
            <PageTitle title="About Us" />
        <section className="my-10">
            <div className="container mb-10">
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
                >
                <Tab eventKey="about" title="About">
                    <AboutSection />
                </Tab>
                <Tab eventKey="mission" title="Mission">
                    <MissionSection />
                </Tab>
                <Tab eventKey="team" title="Team">
                    <Team />
                </Tab>
            </Tabs>
            </div></section>
        </>
    )
}

export default React.memo(About);